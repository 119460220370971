import {
    AccordionContainerDPC,
    AccordionDPC,
    CardHorizontalContainerDPC,
    CardHorizontalDPC,
    PageContainerDPC,
    SocialPanelContainerDPC,
    PhotoVideoContainerAnticDPC,
    CarouselMinisitiDPC,
    LinkUtiliDPC,
    CardContainerDPC,
    CardDPC,
    AgendaDPC,
    SvgIconDPC
} from '@dpc-fe/shared';
import {graphql} from 'gatsby';
import React from 'react';
import {getSlug, getAccordionAndTabLinks, prop, getCategoriaPrimariaEN} from "../functions/functions";
import {getAbstract} from "@dpc-fe/shared/js/shared-functions";
import moment from "moment";

export default ({data, pageContext}) => {
    const primoPianoContenuto = data.node.relationships.field_primo_piano_contenuto;
    const primoPianoStreaming = data.node.relationships.field_primo_piano_streaming;
    const primoPianoImage = primoPianoContenuto && primoPianoContenuto.relationships.field_immagine_anteprima ? primoPianoContenuto.relationships.field_immagine_anteprima : null;
    const tipoSocial = data.node.relationships.field_box_social ? data.node.relationships.field_box_social.field_tipo_social : null;
    const accordion = data.node.relationships.field_accordion;
    const faq = data.node.relationships.field_link_bottone_singolo;
    const box1 = [
        data.node.relationships.field_box_1_riga_1,
        data.node.relationships.field_box_1_riga_2,
        data.node.relationships.field_box_1_riga_3
    ];
    const box2 = [
        data.node.relationships.field_box_2_riga_1,
        data.node.relationships.field_box_2_riga_2,
        data.node.relationships.field_box_2_riga_3
    ];
    const box3 = [
        data.node.relationships.field_box_3_riga_1,
        data.node.relationships.field_box_3_riga_2,
        data.node.relationships.field_box_3_riga_3
    ];
    const rullo1 = data.node.relationships.field_box_rullo_1 ? data.node.relationships.field_box_rullo_1.relationships.field_contenuto : [];
    const rullo2 = data.node.relationships.field_box_rullo_2 ? data.node.relationships.field_box_rullo_2.relationships.field_contenuto : [];
    const linkUtiliArray = data.node.relationships.field_link_utili_1.concat(data.node.relationships.field_link_utili_2, data.node.relationships.field_link_utili_3);
    const linkUtili = linkUtiliArray.map(item => {
        if (item.field_link_esterno) {
            return {
                slug: item.field_link.uri,
                titleLinkEsterno: "Vai al sito " + item.field_link.uri,
                title: item.field_titolo_link ? item.field_titolo_link : item.field_link.title
            }
        } else if (!item.field_link_esterno && item.relationships.field_link_interno) {
            return {
                slug: getSlug('/' + pageContext.lang + (item.relationships.field_link_interno.fields ? item.relationships.field_link_interno.fields.slug : ''), process.env.PORTALE, item.relationships.field_link_interno.relationships.field_sottodominio.name),
                title: item.field_titolo_link ? item.field_titolo_link : item.relationships.field_link_interno.title
            }
        }
    });

    const galleriaFoto = data.node.relationships.field_foto_video && data.node.relationships.field_foto_video.map(item => {
        return {
            src: item.field_categoria_primaria.toLowerCase() === 'video' ? item.field_video?.uri : item.relationships?.field_immagine_dettaglio?.relationships?.image?.localFile?.publicURL,
            alt: item.field_alt ? item.field_alt : item?.relationships?.field_immagine_dettaglio?.field_alt,
            didascalia: item.field_didascalia,
            link: getSlug('/' + pageContext.lang + item.fields.slug, process.env.PORTALE, item.relationships.field_sottodominio.name),
            linkTitle: item.field_titolo_esteso,
            categoria: item.field_categoria_primaria
        }
    });

    const getRulloClass = () => {
        if (rullo1.length > 0 && rullo2 <= 0 && !tipoSocial) {
            return 'col-12'
        } else if (rullo1.length > 0 && rullo2.length <= 0 && tipoSocial) {
            return 'col-12 col-md-6 col-xl-8'
        } else if (rullo1.length > 0 && rullo2.length > 0 && !tipoSocial) {
            return 'col-12 col-sm-6'
        } else if (rullo1.length > 0 && rullo2.length > 0 && tipoSocial) {
            return 'col-12 col-md-6 col-xl-4'
        } else if (rullo2.length > 0 && rullo1.length <= 0 && tipoSocial) {
            return 'col-12 col-md-6 col-xl-8'
        } else if (rullo2.length > 0 && rullo1 <= 0 && !tipoSocial) {
            return 'col-12'
        }
    }

    return (
        <PageContainerDPC basePathNavbar={process.env.PORTALE_URL} classeAggiuntivaSubnavbar={"has-header-subnavbar"}
                          menuItems={pageContext.menu}
                          slugTraduzione={data.node.relationships.field_riferimento_traduzione ? data.node.relationships.field_riferimento_traduzione.fields.slug : ''}
                          pageId={data.node.drupal_internal__nid}
                          title={data.node.title}
                          env={process.env.ENV}
                          lang={pageContext.lang}
                          immagineShare={data.node.relationships?.field_immagine_dettaglio ? process.env.PORTALE_URL + data.node.relationships?.field_immagine_dettaglio?.relationships?.image?.localFile?.publicURL: process.env.PORTALE_URL + pageContext.socialImg?.relationships?.image?.localFile?.publicURL}
                          abstractShare={data.node.body?.processed ? data.node.body.processed.replace(/<\/?[^>]+(>|$)/g, "") : ''}
                          slug={process.env.PORTALE_URL + '/' + pageContext.lang + data.node.fields.slug}
                          minisiti={prop}
                          menuFooterItems={pageContext.menuFooter}
        >

            {data.node.field_box_primo_piano_immagine && 
            <CardHorizontalContainerDPC titoloSezione={data.node.field_titolo_esteso}>
                <CardHorizontalDPC                  
                    body={data.node.field_abstract ? data.node.field_abstract.processed : ''}
                    image={data?.node?.relationships?.field_immagine_anteprima}
                    testoIntero={true}                    
                />
            </CardHorizontalContainerDPC> 
            }
            {(!data.node.field_box_primo_piano_immagine && primoPianoContenuto && !data.node.field_streaming_homepage) &&
            <CardHorizontalContainerDPC titoloSezione={'Featured'}>
                <CardHorizontalDPC
                    title={primoPianoContenuto.field_titolo_esteso}
                    body={primoPianoContenuto.field_abstract ? primoPianoContenuto.field_abstract.value : ''}
                    slug={getSlug(primoPianoContenuto.fields ? '/' + pageContext.lang + primoPianoContenuto.fields.slug : '', process.env.PORTALE, primoPianoContenuto.relationships.field_sottodominio.name)}
                    image={primoPianoImage}
                    slugText={'Read more'}
                />
            </CardHorizontalContainerDPC>
            }

            {(!data.node.field_box_primo_piano_immagine && primoPianoStreaming && data.node.field_streaming_homepage) &&
            <CardHorizontalContainerDPC titoloSezione={'Featured'}>
                <CardHorizontalDPC
                    title={primoPianoStreaming.field_titolo}
                    body={primoPianoStreaming.field_testo?.value}
                    streaming={primoPianoStreaming}
                />
            </CardHorizontalContainerDPC>}

            {Array.isArray(box1) && box1.length > 0 && box1.map(boxs =>
                (Array.isArray(boxs) && boxs.length > 0) && <CardContainerDPC titoloSezione={''}>
                    {boxs.map(box =>
                        <div className="col-12 col-md-4">
                            <article className="card-wrapper card-space">
                                <div className="card card-img">
                                    <div className="img-responsive-wrapper">
                                        <div className="img-responsive">
                                            <figure className="img-wrapper">
<figcaption className="sr-only">{box.relationships?.field_immagine_dettaglio?.field_didascalia}</figcaption>
                                                <a href={getSlug(box.fields ? '/' + pageContext.lang + box.fields.slug : '', process.env.PORTALE, box.relationships?.field_sottodominio.name)}>
                                                    <img alt={box.relationships?.field_immagine_dettaglio?.field_alt}
                                                         src={box.relationships?.field_immagine_dettaglio ? box.relationships.field_immagine_dettaglio.relationships.image.localFile.publicURL : null}/>
                                                </a>
                                            </figure>
                                        </div>
                                    </div>
                                    <div className="card-body">
                                        <h3 className="card-title">
                                            <a href={getSlug(box.fields ? '/' + pageContext.lang + box.fields.slug : '', process.env.PORTALE, box.relationships?.field_sottodominio.name)}>
                                                {box.field_titolo_esteso}
                                            </a>
                                        </h3>
                                        {/*<a className="read-more"
                                           href={getSlug(box.fields ? '/' + pageContext.lang + box.fields.slug : '', process.env.PORTALE, box.relationships?.field_sottodominio.name)}>
                                            <span className="text">Leggi di pi&ugrave;</span>
                                            <SvgIconDPC name={'arrow-right'}/>
                                        </a>*/}
                                    </div>
                                </div>
                            </article>
                        </div>
                    )}
                </CardContainerDPC>
            )}

            {Array.isArray(box2) && box2.length > 0 && box2.map(boxs =>
                (Array.isArray(boxs) && boxs.length > 0) && <CardContainerDPC titoloSezione={''}>
                    {boxs.map(box =>
                        <div className="col-12 col-md-4">
                            <CardDPC
                                title={box.field_titolo_esteso}
                                body={box.field_abstract ? box.field_abstract.value : null}
                                slug={getSlug(box.fields ? '/' + pageContext.lang + box.fields.slug : '', process.env.PORTALE, box.relationships?.field_sottodominio.name)}
                                image={box.relationships?.field_immagine_dettaglio}
                            />
                        </div>
                    )}
                </CardContainerDPC>
            )}

            {Array.isArray(box3) && box3.length > 0 && box3.map(boxs =>
                (Array.isArray(boxs) && boxs.length > 0) && <CardContainerDPC titoloSezione={''}>
                    {boxs.map(box =>
                        <div className="col-12 col-md-6">
                            <div className="card w-100">
                                <div className="row">
                                    <div className="col-12 col-xl-6">
                                        <img
                                            src={box.relationships?.field_immagine_dettaglio ? box.relationships.field_immagine_dettaglio.relationships.image.localFile.childImageSharp.fluid.src : null}
                                            className="card-img" alt={box.relationships?.field_immagine_dettaglio?.field_alt}/>
                                    </div>
                                    <div className="col-12 col-xl-6">
                                        <div className="card-body">
                                            <h5 className="card-title">
                                                <a href={getSlug(box.fields ? '/' + pageContext.lang + box.fields.slug : '', process.env.PORTALE, box.relationships.field_sottodominio.name)}>
                                                    {box.field_titolo_esteso}
                                                </a>
                                            </h5>
                                            {box.field_abstract &&
                                            <div className="card-text"
                                                 dangerouslySetInnerHTML={{__html: getAbstract(box.field_abstract.processed, 100)}}/>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </CardContainerDPC>
            )}


            {data.node.field_agenda &&
            <AgendaDPC eventi={data.eventi} locale={"en"}   portale={process.env.PORTALE} minisiti={prop}/>
            }

            {(rullo1 || rullo2) &&
            <div className="container">
                <div className="px-custom">
                    <div className="row">
                        {(Array.isArray(rullo1) && rullo1.length > 0) &&
                        <div
                            className={getRulloClass()}>
                            <section className="article-card-simple rulli">
                                <h2>{data.node.relationships.field_box_rullo_1.field_titolo}</h2>
                                {rullo1.map(item => (
                                    <article className="card-wrapper">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="category-top">
                                                    <span
                                                        className="category">{item.relationships?.field_tipo_provvedimento && item.relationships?.field_tipo_provvedimento?.length > 0 ? item.relationships.field_tipo_provvedimento[0].field_etichetta_en : getCategoriaPrimariaEN(item.field_categoria_primaria).replace(/_/g, ' ')}</span>
                                                    <span
                                                        className="data">{moment(item.field_data).format('D MMMM Y')}</span>
                                                    <h2 className="card-title">
                                                        <a href={getSlug(item.fields ? '/' + pageContext.lang + item.fields.slug : '', process.env.PORTALE, item.relationships?.field_sottodominio?.name)}>
                                                            {item.field_titolo_esteso}
                                                        </a>
                                                    </h2>
                                                </div>
                                                <div className="card-text"
                                                     dangerouslySetInnerHTML={{__html: (item.field_abstract ? item.field_abstract.processed : '')}}/>
                                            </div>
                                        </div>
                                    </article>
                                ))
                                }
                                <div className="card-wrapper">
                                    <div className="card">
                                        <div className="card-body">
                                            <a className="read-more position-static"
                                               href={getSlug('/' + pageContext.lang + data.node.relationships.field_box_rullo_1.relationships.field_link_vedi_tutti?.fields?.slug, process.env.PORTALE, data.node.relationships.field_box_rullo_1.relationships.field_link_vedi_tutti?.relationships?.field_sottodominio?.name)}>
                                            <span className="text">See all</span>
                                                <SvgIconDPC name="arrow-right"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        }
                        {(Array.isArray(rullo2) && rullo2.length > 0) &&
                        <div
                            className={getRulloClass()}>
                            <section className="article-card-simple rulli">
                                <h2>{data.node.relationships.field_box_rullo_2.field_titolo}</h2>
                                {rullo2.map(item => (
                                    <article className="card-wrapper">
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="category-top">
                                                    <span
                                                        className="category">{item.relationships?.field_tipo_provvedimento && item.relationships?.field_tipo_provvedimento?.length > 0 ? item.relationships.field_tipo_provvedimento[0].field_etichetta_en : getCategoriaPrimariaEN(item.field_categoria_primaria).replace(/_/g, ' ')}</span>
                                                    <span
                                                        className="data">{moment(item.field_data).format('D MMMM Y')}</span>
                                                    <h2 className="card-title">
                                                        <a href={getSlug(item.fields ? '/' + pageContext.lang + item.fields.slug : '', process.env.PORTALE, item.relationships?.field_sottodominio?.name)}>
                                                            {item.field_titolo_esteso}
                                                        </a>
                                                    </h2>
                                                </div>
                                                <div className="card-text"
                                                     dangerouslySetInnerHTML={{__html: (item.field_abstract ? item.field_abstract.processed : '')}}/>
                                            </div>
                                        </div>
                                    </article>
                                ))}
                                <div className="card-wrapper">
                                    <div className="card">
                                        <div className="card-body">
                                            <a className="read-more position-static"
                                               href={getSlug('/' + pageContext.lang + data.node.relationships.field_box_rullo_2.relationships.field_link_vedi_tutti?.fields?.slug, process.env.PORTALE, data.node.relationships.field_box_rullo_2.relationships.field_link_vedi_tutti?.relationships?.field_sottodominio?.name)}>
                                                <span className="text">See all</span>
                                                <SvgIconDPC name="arrow-right"/>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </div>
                        }
                        {Array.isArray(tipoSocial) && tipoSocial.length > 0 &&
                            <SocialPanelContainerDPC
                            
                                listaSocial = {tipoSocial}
                                titolo= {data?.node?.relationships?.field_box_social?.field_titolo}>
                            </SocialPanelContainerDPC>
                            }
                    </div>
                </div>
            </div>
            }

            {(Array.isArray(galleriaFoto) && galleriaFoto.length > 0) &&
            <PhotoVideoContainerAnticDPC
                title={'Photo and Video'}
            >
                <CarouselMinisitiDPC
                    items={galleriaFoto}
                    lang={`/` + pageContext.lang}
                    itemsPerPage={3}
                />
            </PhotoVideoContainerAnticDPC>}


            <section className="container">
                {Array.isArray(accordion) && accordion.length > 0 &&
                <h2>{data.node.field_titolo_sezione_accordion}</h2>
                }
                <div className="px-custom">
                    {Array.isArray(accordion) && accordion.length > 0 &&
                    <AccordionContainerDPC id={"1"}>
                        {
                            accordion.map(node => (
                                <AccordionDPC
                                    title={node.field_titolo ? node.field_titolo : ''}
                                    body={node.field_testo ? node.field_testo.processed : ''}
                                    id={node.drupal_internal__id}
                                    accordionId={"1"}
                                    img={node.relationships.field_immagine}
                                    tabella={node.field_tabella ? node.field_tabella.processed : null}
                                    video={node.relationships.field_video}
                                    links={node.relationships ? getAccordionAndTabLinks(node.relationships.field_link_interni, pageContext.lang, process.env.PORTALE) : null}
                                />
                            ))
                        }
                    </AccordionContainerDPC>
                    }
                    {
                        faq && <div className="btn-block-uppercase-wrapper">
                            <a href={getSlug('/' + pageContext.lang + faq.fields.slug, process.env.PORTALE, faq.relationships.field_sottodominio.name)}
                               className="btn btn-tayeb-charcoal" role="button">{faq.field_titolo_esteso}</a>
                        </div>
                    }
                </div>
            </section>

            {Array.isArray(linkUtili) && linkUtili.length > 0 &&
            <LinkUtiliDPC titoloSezione={'Useful Links'} linkUtili={linkUtili}/>
            }
        </PageContainerDPC>
    );
};

export const query = graphql`
query($slug: String!) {
    node: nodeSezioneConAnticipazioni (fields: { slug: { eq: $slug } }) {
        field_categoria_primaria
        title
        field_streaming_homepage
        field_agenda
        field_titolo_esteso
        field_data
        drupal_internal__nid
        field_titolo_sezione_accordion
        field_id_contenuto_originale
        field_box_primo_piano_immagine
        fields {
            slug
        }
        body {
            value
            processed
        }
        field_abstract {
            value
            processed
          }
        relationships {
            field_immagine_dettaglio {
                field_alt
                field_didascalia
                relationships {
                  image:field_immagine {
                    localFile {
                      publicURL
                      childImageSharp {
                        fluid(maxWidth: 1100) {
                          ...GatsbyImageSharpFluid_noBase64
                        }
                      }
                    }
                  }
                }
            }
            field_immagine_anteprima {
            field_alt
            field_didascalia
            relationships {
                image: field_immagine {
                localFile {
                    publicURL
                    childImageSharp {
                    fluid(maxWidth: 1100) {
                        ...GatsbyImageSharpFluid_noBase64
                    }
                    }
                }
                }
            }
            }
            field_sottodominio {
                name
            }
            field_riferimento_traduzione {
                fields {
                    slug
                }
            }
            field_accordion {
                field_titolo
                field_tabella {
                    processed
                }
                field_testo {
                    processed
                    value
                }
                relationships {
                    field_immagine {
                        field_alt
                        field_didascalia
                        relationships {
                            image:field_immagine{
              localFile {
			    publicURL
                childImageSharp {
                                        fluid(maxWidth: 1100) {
                                            ...GatsbyImageSharpFluid_noBase64
                                        }
                                    }
                                }
                            }
                        }
                    }
                    field_video {
                        field_video {
                            title
                            uri
                        }
                        field_didascalia
                        title
                        field_titolo_esteso
                    }
                    field_link_interni{
                        ...pubblicazioneFragment
              ...notiziaFragment
              ...approfondimentoFragment
              ...normativaFragment
              ...pageFragment
              ...bandoDiGaraFragment
              ...comunicatoStampaFragment
              ...corsoFragment
              ...mappaFragment
              ...timelineFragment
              ...sezioneConAnticipazioniFragment
              ...sezioneSenzaAnticipazioniFragment
              ...elencoApprofondimentiFragment
              ...elencoGlossariFragment
              ...elencoAttualitaFragment
              ...elencoPodcastFragment
              ...elencoPubblicazioniFragment
              ...elencoNormativaFragment
              ...elencoBandiEContrattiFragment
              ...elencoFotoVideoFragment
              ...homepageVolontariatoFragment
              ...homepageRelazioniFragment
              ...homepageRischiFragment
              ...homepageNazionaleFragment
              ...homepageMappeFragment
              ...homepageGiovaniFragment
              ...homepageFormazioneFragment
              ...homepageEventiFragment
              ...homepageEmergenzeFragment
              ...homepageDocsFragment
              ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
              ...bollettinoCriticitaFragment
              ...bollettinoVigilanzaFragment
              ...sezioneAllertaMeteoIdroFragment
              ...galleriaFotoFragment
              ...videoFragment
              ...galleriaFlickrFragment
                    }
                }
                drupal_internal__id
                }
                field_box_1_riga_1 {
                    ...pubblicazioneFragment
              ...notiziaFragment
              ...approfondimentoFragment
              ...normativaFragment
              ...pageFragment
              ...bandoDiGaraFragment
              ...comunicatoStampaFragment
              ...corsoFragment
              ...mappaFragment
              ...timelineFragment
              ...sezioneConAnticipazioniFragment
              ...sezioneSenzaAnticipazioniFragment
              ...elencoApprofondimentiFragment
              ...elencoGlossariFragment
              ...elencoAttualitaFragment
              ...elencoPodcastFragment
              ...elencoPubblicazioniFragment
              ...elencoNormativaFragment
              ...elencoBandiEContrattiFragment
              ...elencoFotoVideoFragment
              ...homepageVolontariatoFragment
              ...homepageRelazioniFragment
              ...homepageRischiFragment
              ...homepageNazionaleFragment
              ...homepageMappeFragment
              ...homepageGiovaniFragment
              ...homepageFormazioneFragment
              ...homepageEventiFragment
              ...homepageEmergenzeFragment
              ...homepageDocsFragment
              ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
              ...bollettinoCriticitaFragment
              ...bollettinoVigilanzaFragment
              ...sezioneAllertaMeteoIdroFragment
              ...galleriaFotoFragment
              ...videoFragment
              ...galleriaFlickrFragment
                }
                field_box_1_riga_2 {
                    ...pubblicazioneFragment
              ...notiziaFragment
              ...approfondimentoFragment
              ...normativaFragment
              ...pageFragment
              ...bandoDiGaraFragment
              ...comunicatoStampaFragment
              ...corsoFragment
              ...mappaFragment
              ...timelineFragment
              ...sezioneConAnticipazioniFragment
              ...sezioneSenzaAnticipazioniFragment
              ...elencoApprofondimentiFragment
              ...elencoGlossariFragment
              ...elencoAttualitaFragment
              ...elencoPodcastFragment
              ...elencoPubblicazioniFragment
              ...elencoNormativaFragment
              ...elencoBandiEContrattiFragment
              ...elencoFotoVideoFragment
              ...homepageVolontariatoFragment
              ...homepageRelazioniFragment
              ...homepageRischiFragment
              ...homepageNazionaleFragment
              ...homepageMappeFragment
              ...homepageGiovaniFragment
              ...homepageFormazioneFragment
              ...homepageEventiFragment
              ...homepageEmergenzeFragment
              ...homepageDocsFragment
              ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
              ...bollettinoCriticitaFragment
              ...bollettinoVigilanzaFragment
              ...sezioneAllertaMeteoIdroFragment
              ...galleriaFotoFragment
              ...videoFragment
              ...galleriaFlickrFragment
                }
                field_box_1_riga_3 {
                    ...pubblicazioneFragment
              ...notiziaFragment
              ...approfondimentoFragment
              ...normativaFragment
              ...pageFragment
              ...bandoDiGaraFragment
              ...comunicatoStampaFragment
              ...corsoFragment
              ...mappaFragment
              ...timelineFragment
              ...sezioneConAnticipazioniFragment
              ...sezioneSenzaAnticipazioniFragment
              ...elencoApprofondimentiFragment
              ...elencoGlossariFragment
              ...elencoAttualitaFragment
              ...elencoPodcastFragment
              ...elencoPubblicazioniFragment
              ...elencoNormativaFragment
              ...elencoBandiEContrattiFragment
              ...elencoFotoVideoFragment
              ...homepageVolontariatoFragment
              ...homepageRelazioniFragment
              ...homepageRischiFragment
              ...homepageNazionaleFragment
              ...homepageMappeFragment
              ...homepageGiovaniFragment
              ...homepageFormazioneFragment
              ...homepageEventiFragment
              ...homepageEmergenzeFragment
              ...homepageDocsFragment
              ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
              ...bollettinoCriticitaFragment
              ...bollettinoVigilanzaFragment
              ...sezioneAllertaMeteoIdroFragment
              ...galleriaFotoFragment
              ...videoFragment
              ...galleriaFlickrFragment
                }
                field_box_2_riga_1 {
                    ...pubblicazioneFragment
              ...notiziaFragment
              ...approfondimentoFragment
              ...normativaFragment
              ...pageFragment
              ...bandoDiGaraFragment
              ...comunicatoStampaFragment
              ...corsoFragment
              ...mappaFragment
              ...timelineFragment
              ...sezioneConAnticipazioniFragment
              ...sezioneSenzaAnticipazioniFragment
              ...elencoApprofondimentiFragment
              ...elencoGlossariFragment
              ...elencoAttualitaFragment
              ...elencoPodcastFragment
              ...elencoPubblicazioniFragment
              ...elencoNormativaFragment
              ...elencoBandiEContrattiFragment
              ...elencoFotoVideoFragment
              ...homepageVolontariatoFragment
              ...homepageRelazioniFragment
              ...homepageRischiFragment
              ...homepageNazionaleFragment
              ...homepageMappeFragment
              ...homepageGiovaniFragment
              ...homepageFormazioneFragment
              ...homepageEventiFragment
              ...homepageEmergenzeFragment
              ...homepageDocsFragment
              ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
              ...bollettinoCriticitaFragment
              ...bollettinoVigilanzaFragment
              ...sezioneAllertaMeteoIdroFragment
              ...galleriaFotoFragment
              ...videoFragment
              ...galleriaFlickrFragment
                }
                field_box_2_riga_2 {
                    ...pubblicazioneFragment
              ...notiziaFragment
              ...approfondimentoFragment
              ...normativaFragment
              ...pageFragment
              ...bandoDiGaraFragment
              ...comunicatoStampaFragment
              ...corsoFragment
              ...mappaFragment
              ...timelineFragment
              ...sezioneConAnticipazioniFragment
              ...sezioneSenzaAnticipazioniFragment
              ...elencoApprofondimentiFragment
              ...elencoGlossariFragment
              ...elencoAttualitaFragment
              ...elencoPodcastFragment
              ...elencoPubblicazioniFragment
              ...elencoNormativaFragment
              ...elencoBandiEContrattiFragment
              ...elencoFotoVideoFragment
              ...homepageVolontariatoFragment
              ...homepageRelazioniFragment
              ...homepageRischiFragment
              ...homepageNazionaleFragment
              ...homepageMappeFragment
              ...homepageGiovaniFragment
              ...homepageFormazioneFragment
              ...homepageEventiFragment
              ...homepageEmergenzeFragment
              ...homepageDocsFragment
              ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
              ...bollettinoCriticitaFragment
              ...bollettinoVigilanzaFragment
              ...sezioneAllertaMeteoIdroFragment
              ...galleriaFotoFragment
              ...videoFragment
              ...galleriaFlickrFragment
                }
                field_box_2_riga_3 {
                   ...pubblicazioneFragment
              ...notiziaFragment
              ...approfondimentoFragment
              ...normativaFragment
              ...pageFragment
              ...bandoDiGaraFragment
              ...comunicatoStampaFragment
              ...corsoFragment
              ...mappaFragment
              ...timelineFragment
              ...sezioneConAnticipazioniFragment
              ...sezioneSenzaAnticipazioniFragment
              ...elencoApprofondimentiFragment
              ...elencoGlossariFragment
              ...elencoAttualitaFragment
              ...elencoPodcastFragment
              ...elencoPubblicazioniFragment
              ...elencoNormativaFragment
              ...elencoBandiEContrattiFragment
              ...elencoFotoVideoFragment
              ...homepageVolontariatoFragment
              ...homepageRelazioniFragment
              ...homepageRischiFragment
              ...homepageNazionaleFragment
              ...homepageMappeFragment
              ...homepageGiovaniFragment
              ...homepageFormazioneFragment
              ...homepageEventiFragment
              ...homepageEmergenzeFragment
              ...homepageDocsFragment
              ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
              ...bollettinoCriticitaFragment
              ...bollettinoVigilanzaFragment
              ...sezioneAllertaMeteoIdroFragment
              ...galleriaFotoFragment
              ...videoFragment
              ...galleriaFlickrFragment
                }
                field_box_3_riga_1 {
                    ...pubblicazioneFragment
              ...notiziaFragment
              ...approfondimentoFragment
              ...normativaFragment
              ...pageFragment
              ...bandoDiGaraFragment
              ...comunicatoStampaFragment
              ...corsoFragment
              ...mappaFragment
              ...timelineFragment
              ...sezioneConAnticipazioniFragment
              ...sezioneSenzaAnticipazioniFragment
              ...elencoApprofondimentiFragment
              ...elencoGlossariFragment
              ...elencoAttualitaFragment
              ...elencoPodcastFragment
              ...elencoPubblicazioniFragment
              ...elencoNormativaFragment
              ...elencoBandiEContrattiFragment
              ...elencoFotoVideoFragment
              ...homepageVolontariatoFragment
              ...homepageRelazioniFragment
              ...homepageRischiFragment
              ...homepageNazionaleFragment
              ...homepageMappeFragment
              ...homepageGiovaniFragment
              ...homepageFormazioneFragment
              ...homepageEventiFragment
              ...homepageEmergenzeFragment
              ...homepageDocsFragment
              ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
              ...bollettinoCriticitaFragment
              ...bollettinoVigilanzaFragment
              ...sezioneAllertaMeteoIdroFragment
              ...galleriaFotoFragment
              ...videoFragment
              ...galleriaFlickrFragment
                }
                field_box_3_riga_2 {
                    ...pubblicazioneFragment
              ...notiziaFragment
              ...approfondimentoFragment
              ...normativaFragment
              ...pageFragment
              ...bandoDiGaraFragment
              ...comunicatoStampaFragment
              ...corsoFragment
              ...mappaFragment
              ...timelineFragment
              ...sezioneConAnticipazioniFragment
              ...sezioneSenzaAnticipazioniFragment
              ...elencoApprofondimentiFragment
              ...elencoGlossariFragment
              ...elencoAttualitaFragment
              ...elencoPodcastFragment
              ...elencoPubblicazioniFragment
              ...elencoNormativaFragment
              ...elencoBandiEContrattiFragment
              ...elencoFotoVideoFragment
              ...homepageVolontariatoFragment
              ...homepageRelazioniFragment
              ...homepageRischiFragment
              ...homepageNazionaleFragment
              ...homepageMappeFragment
              ...homepageGiovaniFragment
              ...homepageFormazioneFragment
              ...homepageEventiFragment
              ...homepageEmergenzeFragment
              ...homepageDocsFragment
              ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
              ...bollettinoCriticitaFragment
              ...bollettinoVigilanzaFragment
              ...sezioneAllertaMeteoIdroFragment
              ...galleriaFotoFragment
              ...videoFragment
              ...galleriaFlickrFragment
                }
                field_box_3_riga_3 {
                    ...pubblicazioneFragment
              ...notiziaFragment
              ...approfondimentoFragment
              ...normativaFragment
              ...pageFragment
              ...bandoDiGaraFragment
              ...comunicatoStampaFragment
              ...corsoFragment
              ...mappaFragment
              ...timelineFragment
              ...sezioneConAnticipazioniFragment
              ...sezioneSenzaAnticipazioniFragment
              ...elencoApprofondimentiFragment
              ...elencoGlossariFragment
              ...elencoAttualitaFragment
              ...elencoPodcastFragment
              ...elencoPubblicazioniFragment
              ...elencoNormativaFragment
              ...elencoBandiEContrattiFragment
              ...elencoFotoVideoFragment
              ...homepageVolontariatoFragment
              ...homepageRelazioniFragment
              ...homepageRischiFragment
              ...homepageNazionaleFragment
              ...homepageMappeFragment
              ...homepageGiovaniFragment
              ...homepageFormazioneFragment
              ...homepageEventiFragment
              ...homepageEmergenzeFragment
              ...homepageDocsFragment
              ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
              ...bollettinoCriticitaFragment
              ...bollettinoVigilanzaFragment
              ...sezioneAllertaMeteoIdroFragment
              ...galleriaFotoFragment
              ...videoFragment
              ...galleriaFlickrFragment
                }
                field_box_rullo_1 {
                    field_titolo
                    field_tipologia
                    relationships {
                        field_contenuto {
                            ...notiziaFragment
                            ...normativaFragment
                            ...bandoDiGaraFragment
                            ...comunicatoStampaFragment
                        }
                        field_link_vedi_tutti {
                            ...elencoAttualitaFragment
                            ...elencoBandiEContrattiFragment
                            ...elencoNormativaFragment
                        }
                    }
                }
                field_box_rullo_2 {
                    field_titolo
                    field_tipologia
                    relationships {
                        field_contenuto {
                            ...notiziaFragment
                            ...normativaFragment
                            ...bandoDiGaraFragment
                            ...comunicatoStampaFragment
                        }
                        field_link_vedi_tutti {
                            ...elencoAttualitaFragment
                            ...elencoBandiEContrattiFragment
                            ...elencoNormativaFragment
                        }
                    }
                }
                field_box_social {
                    field_titolo
                    field_tipo_social
                }
                field_foto_video {
                    ...immagineFragment
                    ...videoFragment
                    ...galleriaFotoFragment
                    ...galleriaFlickrFragment
                }
                field_link_bottone_singolo {
                    ...pubblicazioneFragment
                    ...notiziaFragment
                    ...approfondimentoFragment
                    ...normativaFragment
                    ...pageFragment
                    ...bandoDiGaraFragment
                    ...comunicatoStampaFragment
                    ...corsoFragment
                              ...sezioneConAnticipazioniFragment
          ...sezioneSenzaAnticipazioniFragment
          ...elencoApprofondimentiFragment
          ...elencoGlossariFragment
          ...elencoAttualitaFragment
          ...elencoPodcastFragment
          ...elencoPubblicazioniFragment
          ...elencoNormativaFragment
          ...elencoBandiEContrattiFragment
          ...elencoFotoVideoFragment
          ...homepageVolontariatoFragment
          ...homepageRelazioniFragment
          ...homepageRischiFragment
          ...homepageNazionaleFragment
          ...homepageMappeFragment
          ...homepageGiovaniFragment
          ...homepageFormazioneFragment
          ...homepageEventiFragment
          ...homepageEmergenzeFragment
          ...homepageDocsFragment
          ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
...bollettinoCriticitaFragment
...bollettinoVigilanzaFragment
...sezioneAllertaMeteoIdroFragment
                    
                }
                field_link_utili_1 {
                    field_link_esterno
                    field_titolo_link
                    field_link {
                        title
                        uri
                    }
                    relationships {
                        field_link_interno {
                            ...pubblicazioneFragment
                            ...notiziaFragment
                            ...approfondimentoFragment
                            ...normativaFragment
                            ...pageFragment
                            ...bandoDiGaraFragment
                            ...comunicatoStampaFragment
                            ...corsoFragment
                            ...mappaFragment
                            ...timelineFragment
                                      ...sezioneConAnticipazioniFragment
          ...sezioneSenzaAnticipazioniFragment
          ...elencoApprofondimentiFragment
          ...elencoGlossariFragment
          ...elencoAttualitaFragment
          ...elencoPodcastFragment
          ...elencoPubblicazioniFragment
          ...elencoNormativaFragment
          ...elencoBandiEContrattiFragment
          ...elencoFotoVideoFragment
          ...homepageVolontariatoFragment
          ...homepageRelazioniFragment
          ...homepageRischiFragment
          ...homepageNazionaleFragment
          ...homepageMappeFragment
          ...homepageGiovaniFragment
          ...homepageFormazioneFragment
          ...homepageEventiFragment
          ...homepageEmergenzeFragment
          ...homepageDocsFragment
          ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
...bollettinoCriticitaFragment
...bollettinoVigilanzaFragment
...sezioneAllertaMeteoIdroFragment
                        }
                    }
                }
                field_link_utili_2 {
                    field_link_esterno
                    field_titolo_link
                    field_link {
                        title
                    uri
                }
                relationships {
                    field_link_interno {
                        ...pubblicazioneFragment
                        ...notiziaFragment
                        ...approfondimentoFragment
                        ...normativaFragment
                        ...pageFragment
                        ...bandoDiGaraFragment
                        ...comunicatoStampaFragment
                        ...corsoFragment
                        ...mappaFragment
                        ...timelineFragment
                                  ...sezioneConAnticipazioniFragment
          ...sezioneSenzaAnticipazioniFragment
          ...elencoApprofondimentiFragment
          ...elencoGlossariFragment
          ...elencoAttualitaFragment
          ...elencoPodcastFragment
          ...elencoPubblicazioniFragment
          ...elencoNormativaFragment
          ...elencoBandiEContrattiFragment
          ...elencoFotoVideoFragment
          ...homepageVolontariatoFragment
          ...homepageRelazioniFragment
          ...homepageRischiFragment
          ...homepageNazionaleFragment
          ...homepageMappeFragment
          ...homepageGiovaniFragment
          ...homepageFormazioneFragment
          ...homepageEventiFragment
          ...homepageEmergenzeFragment
          ...homepageDocsFragment
          ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
...bollettinoCriticitaFragment
...bollettinoVigilanzaFragment
...sezioneAllertaMeteoIdroFragment
                    }
                    }
                }
                field_link_utili_3 {
                    field_link_esterno
                    field_titolo_link
                    field_link {
                        title
                        uri
                    }
                relationships {
                    field_link_interno {
                        ...pubblicazioneFragment
                        ...notiziaFragment
                        ...approfondimentoFragment
                        ...normativaFragment
                        ...pageFragment
                        ...bandoDiGaraFragment
                        ...comunicatoStampaFragment
                        ...corsoFragment
                        ...mappaFragment
                        ...timelineFragment
                                  ...sezioneConAnticipazioniFragment
          ...sezioneSenzaAnticipazioniFragment
          ...elencoApprofondimentiFragment
          ...elencoGlossariFragment
          ...elencoAttualitaFragment
          ...elencoPodcastFragment
          ...elencoPubblicazioniFragment
          ...elencoNormativaFragment
          ...elencoBandiEContrattiFragment
          ...elencoFotoVideoFragment
          ...homepageVolontariatoFragment
          ...homepageRelazioniFragment
          ...homepageRischiFragment
          ...homepageNazionaleFragment
          ...homepageMappeFragment
          ...homepageGiovaniFragment
          ...homepageFormazioneFragment
          ...homepageEventiFragment
          ...homepageEmergenzeFragment
          ...homepageDocsFragment
          ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
...bollettinoCriticitaFragment
...bollettinoVigilanzaFragment
...sezioneAllertaMeteoIdroFragment
                    }
                }
                }
                field_primo_piano_contenuto {
                    ...pubblicazioneFragment
                    ...notiziaFragment
                    ...approfondimentoFragment
                    ...normativaFragment
                    ...pageFragment
                    ...bandoDiGaraFragment
                    ...comunicatoStampaFragment
                    ...corsoFragment
                    ...mappaFragment
                    ...timelineFragment
                              ...sezioneConAnticipazioniFragment
          ...sezioneSenzaAnticipazioniFragment
          ...elencoApprofondimentiFragment
          ...elencoGlossariFragment
          ...elencoAttualitaFragment
          ...elencoPodcastFragment
          ...elencoPubblicazioniFragment
          ...elencoNormativaFragment
          ...elencoBandiEContrattiFragment
          ...elencoFotoVideoFragment
          ...homepageVolontariatoFragment
          ...homepageRelazioniFragment
          ...homepageRischiFragment
          ...homepageNazionaleFragment
          ...homepageMappeFragment
          ...homepageGiovaniFragment
          ...homepageFormazioneFragment
          ...homepageEventiFragment
          ...homepageEmergenzeFragment
          ...homepageDocsFragment
          ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
...bollettinoCriticitaFragment
...bollettinoVigilanzaFragment
...sezioneAllertaMeteoIdroFragment
                }
                field_primo_piano_streaming {
            field_testo {
              value
            }
            field_link {
              uri
              title
            }
            field_titolo
        }
            }
        },
    eventi: allNodeEventi( filter: {field_codice_lingua: {eq: true}, relationships: {field_sottodominio: {name: {eq: "Portale"}}}}) {
        edges {
            node {
                body {
                    processed
                }
                field_link {
                  title
                  uri
                }
                relationships {
                field_sottodominio{
                    name
                    id
                    drupal_id
                    drupal_internal__tid
                }
                    field_link_interno {
                        ...pubblicazioneFragment
                        ...notiziaFragment
                        ...approfondimentoFragment
                        ...normativaFragment
                        ...pageFragment
                        ...bandoDiGaraFragment
                        ...comunicatoStampaFragment
                        ...corsoFragment
                        ...mappaFragment
                        ...timelineFragment
                                  ...sezioneConAnticipazioniFragment
          ...sezioneSenzaAnticipazioniFragment
          ...elencoApprofondimentiFragment
          ...elencoGlossariFragment
          ...elencoAttualitaFragment
          ...elencoPodcastFragment
          ...elencoPubblicazioniFragment
          ...elencoNormativaFragment
          ...elencoBandiEContrattiFragment
          ...elencoFotoVideoFragment
          ...homepageVolontariatoFragment
          ...homepageRelazioniFragment
          ...homepageRischiFragment
          ...homepageNazionaleFragment
          ...homepageMappeFragment
          ...homepageGiovaniFragment
          ...homepageFormazioneFragment
          ...homepageEventiFragment
          ...homepageEmergenzeFragment
          ...homepageDocsFragment
          ...homepageDomandeFragment
...homepagePnrrFragment
...homepageItAlertFragment
...bollettinoCriticitaFragment
...bollettinoVigilanzaFragment
...sezioneAllertaMeteoIdroFragment
                    }
                    field_allegati {
                        title
                        field_titolo_esteso
                        relationships {
                          field_sottodominio {
                            name
                          }
                          field_allegato {
                            filesize
                            filename
                            localFile {
                              publicURL
                              extension
                            }
                          }
                        }
                    }
                }
                field_link_esterno
                field_link_esterno
                field_data_evento
                title
                field_titolo_esteso
                field_luogo
            }
        }
    }
}
`;
